import {
  createRouter,
  // createWebHashHistory,
  createWebHistory
} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [{
  path: '/',
  name: config.isRegister === true ? 'home' : 'register',
  component: config.isRegister === true ? HomeView : () => import('../components/register.vue')
},
{
  //首页使用
  path: '/register',
  name: 'register',
  component: () => import('../components/register.vue')
},//end


{
  path: '/base64',
  name: 'base64',
  component: () => import('../components/base64.vue')
},
{
  path: '/chatdemo',
  name: 'chatdemo',
  component: () => import('../components/chatdemo.vue')
},
{
  path: '/lovetravel',
  name: 'lovetravel',
  component: () => import('../components/loveTravel.vue')
},
{
  path: '/silhouette',
  name: 'silhouette',
  component: () => import('../components/silhouette.vue')
},
{
  path: '/follow/:whoormy',
  name: 'follow',
  component: () => import('../components/follow.vue')
},
{
  path: '/follow/:whoormy',
  name: 'follow',
  component: () => import('../components/follow.vue')
},
{
  path: '/searchobj',
  name: 'searchobj',
  component: () => import('../components/searchobj.vue')
},
{
  path: '/recharge',
  name: 'recharge',
  component: () => import('../components/recharge.vue')
},
{
  path: '/detailpage/:whopenId/:myopenId/:userName',
  name: 'detailpage',
  component: () => import('../components/detailpage.vue')
}, {
  path: '/myhomhall',
  name: 'myhomhall',
  component: () => import('../components/myhomhall.vue')
},
{
  path: '/orderdetail',
  name: 'orderdetail',
  component: () => import('../components/orderdetail.vue')
},
{
  path: '/membershipfee/:contractname/:shipfee',
  name: 'membershipfee',
  component: () => import('../components/membershipfee.vue')
},
{
  path: '/release',
  name: 'release',
  component: () => import('../components/release.vue'),
  meta: {
    keepAlive: true, //此组件需要被缓存
    isBack: true, //用于判断上一个页面是哪个
  }
},
{
  path: '/recorder',
  name: 'recorder',
  component: () => import('../components/recorder.vue')
},
{
  path: '/contract/:contractType',
  name: 'contract',
  component: () => import('../components/contract.vue')
},
{
  path: '/deductioncontract/:onlyNumber/:money',
  name: 'deductioncontract',
  component: () => import('../components/deductioncontract.vue')
},
{
  path: '/deductionfee/:contractname/:shipfee',
  name: 'deductionfee',
  component: () => import('../components/deductionfee.vue')
},
{
  path: '/travelcontractfee/:contractname/:shipfee',
  name: 'travelcontractfee',
  component: () => import('../components/travelcontractfee.vue')
},
{
  path: '/commvideo',
  name: 'commvideo',
  component: () => import('../views/commvideo.vue')
},
{
  path: '/vuequilleditor',
  name: 'vuequilleditor',
  component: () => import('../views/vuequilleditor.vue')
},
{
  path: '/travelcontractactivitygroup',
  name: 'travelcontractactivitygroup',
  component: () => import('../components/travelcontractactivitygroup.vue')
},
{
  path: '/activityIntroduction',
  name: 'activityIntroduction',
  component: () => import('../components/activityIntroduction.vue')
},
{
  path: '/onlineorofflinefee',
  name: 'onlineorofflinefee',
  component: () => import('../components/onlineorofflinefee.vue')
},
{
  path: '/travelcontract',
  name: 'travelcontract',
  component: () => import('../components/travelcontract.vue')
},
{
  path: '/orderdetail/:contractNo',
  name: 'orderdetail',
  component: () => import('../components/orderdetail.vue')
},
{
  path: '/auth',
  name: 'auth',
  component: () => import('../components/auth.vue')
},
{
  path: '/Iwanttocertify',
  name: 'Iwanttocertify',
  component: () => import('../components/Iwanttocertify.vue')
},
{
  path: '/chat',
  name: 'chat',
  component: () => import('../components/chat.vue')
},
{
  path: '/privatechatlist',
  name: 'privatechatlist',
  component: () => import('../components/privatechatlist.vue')
},
{
  path: '/my',
  name: 'my',
  component: () => import('../components/my.vue')
},
{
  path: '/essentialInfo',
  name: 'essentialInfo',
  component: () => import('../components/essentialInfo.vue')
},
{
  path: '/videoOfAudiocall',
  name: 'videoOfAudiocall',
  component: () => import('../views/videoOfAudiocall.vue')
},
{
  path: '/salesnumber/:contractname',
  name: 'salesnumber',
  component: () => import('../components/salesnumber.vue')
},
{
  path: '/optionalpayment/:contractname/:shipfee',
  name: 'optionalpayment',
  component: () => import('../components/optionalpayment.vue')
}
]

const router = createRouter({
  history: createWebHistory(),
  //history: createWebHashHistory(), //带#号的
  routes
})

// 全局监听路由
// router.beforeEach((to, from, next) => {
//   console.log(to.name + "全局监听路由");
//   let access_token = GetLocalStorage("access_token");
//   console.log(t,": access_token")
//   if (t != null && to.name != '/') {
//     next(to.name);
//   }
//   else{
//     next();
//   }
// });

export default router
