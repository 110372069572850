import axios from 'axios'
import {
  GetToken
} from '@/common/auth'
import store from '@/store'
// import router from '@/router'

// const VUE_APP_AUTH_KEY = process.env.VUE_APP_AUTH_KEY

const httpRequest = axios.create({
  baseURL: `${config.baseUrl}/h5service/`, 
  timeout: process.env.VUE_APP_REQUEST_TIMEOUT
})

/**
 *
 *  @param {*} config.下的options字段
 *  @param {*} showLoading 是否显示全屏loading 预留
 *  @param {*} showMessage 业主状态码错误是否提示全屏message
 *  @param {*} message 自定义message信息
 *  @param {*} action 业务动作，根据业务动作提示不同信息，所有业务动作在message.js里定义
 */
// var elLoading;

httpRequest.interceptors.request.use(
  (config) => {
    const options = config.options || {}
    const {
      showLoading
    } = options
    if (showLoading) {

    }
    if (store.getters.token) {
      // config.headers[VUE_APP_AUTH_KEY] = GetToken()
    }
    const {
      method
    } = config
    if (!config.json) {
      if (config.filterEmptyFlag === undefined) {
        config.filterEmptyFlag = true // 默认过滤空值
      }
      if (method === 'post') {
        config.data = config.data ?
          config.filterEmptyFlag ?
            filterEmpty(config.data) :
            config.data : {}
      } else {
        config.params = config.params ? filterEmpty(config.params) : {}
      }
      config.headers['Authorization'] = "Bearer " + GetToken("h5token");

      config.headers['Content-Type'] =
        'application/x-www-form-urlencoded; charset=UTF-8'
      config.data = queryParse(config.data)
    } else {
      config.headers['Authorization'] = "Bearer " + GetToken("h5token");
      // config.params = config.params ? filterEmpty(config.params) : {}
      config.data = config.data ? config.data : {}
      config.headers['Content-Type'] = 'application/json'
    }
    return config
  },
  (error) => {
    // elLoading.close();
    Promise.reject(error)
  }
)

httpRequest.interceptors.response.use(
  (response) => {
    const res = response.data
    if (res.state == 501) {

    }
    if (res.state != 200) {
      return Promise.resolve(res)
    } else {
      return Promise.resolve(res)
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

export function queryParse(query) {
  const res = []
  for (const k in query) {
    res.push(`${encodeURIComponent(k)}=${encodeURIComponent(query[k])}`)
  }
  return res.join('&')
}

export function filterEmpty(obj) {
  return Object.keys(obj).reduce((total, next) => {
    const val = obj[next]
    if (val || typeof val === 'number' || typeof val === 'boolean') {
      total[next] = val
    }
    return total
  }, {})
}

export default httpRequest
