<template>
  <div class="home">
    <Index />
  </div>
</template>

<script>
import Index from '@/components/Index.vue'

export default {
  name: 'HomeView',
  components: {
    Index
  }
}
</script>
