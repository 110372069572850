import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import '../src/static/index.css'
import vueEsign from 'vue-esign'

import {
  DatetimePicker,
  Empty,
  NumberKeyboard,
  Loading,
  Overlay,
  PullRefresh,
  Toast,
  Form,
  Circle,
  Field,
  Search,
  Dialog,
  Tab,
  Tabs,
  Image as VanImage,
  Swipe,
  SwipeItem,
  Tabbar,
  TabbarItem,
  Card,
  Lazyload,
  Icon,
  Grid,
  GridItem,
  Sidebar,
  SidebarItem,
  Col,
  Row,
  Uploader,
  ImagePreview,
  Tag,
  List,
  NavBar,
  Cell,
  Button,
  CellGroup,
  RadioGroup,
  Radio,
  Checkbox,
  CheckboxGroup,
  Area,
  Popup,
  Picker,
  Divider,
  NoticeBar,
  ContactEdit,
} from 'vant'

createApp(App)
  .use(DatetimePicker)
  .use(NumberKeyboard)
  .use(Loading)
  .use(PullRefresh)
  .use(Empty)
  .use(ContactEdit)
  .use(Circle)
  .use(Divider)
  .use(Popup)
  .use(Overlay)
  .use(Picker)
  .use(RadioGroup)
  .use(Popup)
  .use(Radio)
  .use(Form)
  .use(Field)
  .use(Cell)
  .use(Button)
  .use(CellGroup)
  .use(VanImage)
  .use(SwipeItem)
  .use(Swipe)
  .use(Search)
  .use(Tab)
  .use(Tabs)
  .use(Tabbar)
  .use(TabbarItem)
  .use(Card)
  .use(Col)
  .use(Row)
  .use(Uploader)
  .use(ImagePreview)
  .use(Tag)
  .use(List)
  .use(NavBar)
  .use(Checkbox)
  .use(Area)
  .use(Dialog)
  .use(CheckboxGroup)
  .use(vueEsign)
  .use(NoticeBar)
  .use(Toast)
  .use(Lazyload, {
    lazyComponent: true
  })
  .use(Grid)
  .use(GridItem)
  .use(Icon)
  .use(router)

  .use(store).use(Sidebar).use(SidebarItem)
  .mount('#app')
