<template>
  <!-- 搜索对象 -->
  <van-dialog v-model:show="objshow" title="搜索对象" show-cancel-button confirm-button-text="搜索" @confirm="onSubmitSearch">
    <van-form style="margin-bottom: 15px; margin-top: 10px">
      <van-cell-group style="margin: 0" inset>
        <van-field v-model="userName" name="昵称" label="昵称" placeholder="昵称" />
      </van-cell-group>

      <div class="meandwomen">

        <div class="van-cell van-cell--clickable van-field" style="width: 25%">
          子女：
        </div>
        <van-field v-model="maleCount" is-link name="picker" label="男" placeholder="点击选择" @click="showmale = true">
          <template #button v-if='maleCount && maleCount.length > 0'>
            <van-icon name="close" class="close-icon" @click="maleCountClick" />
          </template>
        </van-field>
        <van-popup v-model:show="showmale" position="bottom">
          <van-picker :columns="maleColumns" @confirm="onConfirmmale" @cancel="showmale = false" />
        </van-popup>

        <van-field v-model="femaleCount" is-link name="picker" label="女" placeholder="点击选择" @click="showfemale = true">
          <template #button v-if='femaleCount && femaleCount.length > 0'>
            <van-icon name="close" class="close-icon" @click="femaleCountClick" />
          </template>
        </van-field>
        <van-popup v-model:show="showfemale" position="bottom">
          <van-picker :columns="maleColumns" @confirm="onConfirmfemale" @cancel="showfemale = false" />
        </van-popup>
      </div>

      <!-- 年龄1 -->
      <div class="meandwomen">
        <van-field v-model="age" is-link name="picker" label="年龄：" placeholder="点击选择年龄" @click="showAge = true">
          <template #button v-if='age && age.length > 0'>
            <van-icon name="close" class="close-icon" @click="handleClearClick1" />
          </template>
        </van-field>
        <van-popup v-model:show="showAge" position="bottom">
          <van-picker :columns="ageColumns" @confirm="onConfirmAge" @cancel="showAge = false">

          </van-picker>
        </van-popup>
      </div>
      <!-- 年龄2 -->
      <div class="meandwomen">
        <van-field v-model="age1" is-link name="picker" label="-" placeholder="..." @click="showAge1 = true">
          <template #button v-if='age1 && age1.length > 0'>
            <van-icon name="close" class="close-icon" @click="handleClearClick2" />
          </template>
        </van-field>
        <van-popup v-model:show="showAge1" position="bottom">
          <van-picker :columns="ageColumns" @confirm="onConfirmAge1" @cancel="showAge1 = false" />
        </van-popup>
      </div>
      <div class="meandwomenzf">
        <van-field name="住房" label="住房：">
          <template #input>
            <van-radio-group v-model="housingType" direction="horizontal">
              <van-radio name="1">有</van-radio>
              <van-radio name="2">无</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </div>
      <van-cell-group style="margin: 0" inset>
        <van-field name="职业选择" label="职业选择：">
          <template #input>
            <span v-if="positionjob != ''">{{ positionjob }}</span>
            <span v-else>无</span>
          </template>

        </van-field>
      </van-cell-group>

      <!-- <div class="meandwomen">
        <van-field v-model="position" is-link name="area" label="点击职业：" placeholder="点击选择职业"
          @click="showposition = true" />
        <van-popup v-model:show="showposition" position="bottom">
          <van-area required :area-list="positions" @confirm="onConfirmaposition" @cancel="showposition = false" />
        </van-popup>
      </div> -->

      <div class="meandwomen">
        <van-field v-model="position" is-link name="area" label="职位选择：" placeholder="点击选择职位"
          @click="showposition = true">
          <template #button v-if='position && position.length > 0'>
            <van-icon name="close" class="close-icon" @click="positionClick" />
          </template>
        </van-field>
        <van-popup v-model:show="showposition" position="bottom">
          <van-area required :area-list="positions" @confirm="onConfirmaposition" @cancel="showposition = false" />
        </van-popup>
      </div>
      <div class="meandwomen">
        <van-field v-model="hobbyType" is-link name="picker" label="爱好分类：" placeholder="点击选择爱好"
          @click="showHobby = true">
          <template #button v-if='hobbyType && hobbyType.length > 0'>
            <van-icon name="close" class="close-icon" @click="hobbyTypeClick" />
          </template>
        </van-field>
        <van-popup v-model:show="showHobby" position="bottom">
          <van-picker :columns="hobbyColumns" @confirm="onConfirmHobby" @cancel="showHobby = false" />
        </van-popup>
      </div>

      <!---------------------- 性格分类-------------------->
      <div class="meandwomen">
        <van-field v-model="natureType" is-link name="picker" label="性格分类：" placeholder="点击选择性格"
          @click="shownatureType = true">
          <template #button v-if='natureType && natureType.length > 0'>
            <van-icon name="close" class="close-icon" @click="natureTypeClick" />
          </template>
        </van-field>
        <van-popup v-model:show="shownatureType" position="bottom">
          <van-picker :columns="natureTypeColumns" @confirm="onConfirmnatureType" @cancel="shownatureType = false" />
        </van-popup>
      </div>
    </van-form>
  </van-dialog>

  <!-- 弹出私聊信息列表 -->
  <van-dialog @cancel="cancelprivateshow" style="width: 330px;" :showConfirmButton=false cancelButtonText="关闭"
    v-model:show="privateshow" title="私聊信息" show-cancel-button>
    <!-- <van-pull-refresh v-model="privaterefreshing" @refresh="privateonRefresh"> -->
    <van-list v-model:loading="privateloading" :finished="privatefinished" finished-text="" @load="privateonLoad">
      <van-cell v-for="chatRecord in chatRecords" :key="chatRecord.id" style="height: 45px">
        <!-- <van-image style="float: left" width="50" height="50" :src="chatRecord.headimgUrl" /> -->
        <div class="followinfo" @click="updateReadState(
    chatRecord.customerOpenId,
    chatRecord.id,
    chatRecord.userName,
    chatRecord.customerHeadimgUrl,
    chatRecord.currentPhone,
    chatRecord.sysUserType
  )
    ">
          <div class="privatereplyNot">
            <span>{{ chatRecord.userName }}</span>
            <span>【</span>
            <span class="privateinfo">{{ chatRecord.info }}</span>
            <span>】</span>
          </div>
          <span style="color: red;margin-left: 7px;" v-if="chatRecord.replyNot == false">回复</span>
          <span style="color: green;" v-else>已回复</span>
        </div>
      </van-cell>
    </van-list>
    <!-- </van-pull-refresh> -->
  </van-dialog>
  <!-- 轮播图 -->
  <van-swipe :autoplay="5000" lazy-render>
    <van-swipe-item v-for="image in imgLists" :key="image" :style="{
    backgroundImage: 'url(' + image.address + ')',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    height: '100%',
  }">
    </van-swipe-item>
  </van-swipe>

  <!-- 活动消息 -->
  <div class="personal" v-if="pageModuls.length > 0">
    <div style="float: left; margin-left: 10px; margin-right: 5px">
      <span>
        <van-icon name="location-o" style="color: black" />
      </span>
    </div>
    <div style="width: %; float: left">
      <span style="font-size: 14px;">{{ city }}</span>
    </div>
    <div style="width: 66%; float: left">
      <van-notice-bar @click="callPhone('4000911696')" v-if="imgLists.length > 0" :scrollable="true"
        class="vannoticebar" content="Hot" text="当前正在报名的旅行活动请点击下面的《遇爱旅行》按钮" />
    </div>
  </div>

  <!-- 模块格子选择 -->
  <div class="van-row" v-if="pageModuls.length > 0">
    <div class="vrow" v-for="page in pageModuls">
      <van-image round width="3rem" fit="cover" height="3rem" margin-top="3%" :src="page.address"
        @click="differentEvent(page.clickFunName, page.link)" />
      <p>{{ page.moduleName }}</p>
    </div>
  </div>

  <!----------------------------------------------------- 用户信息展示--------------------------------------->
  <van-pull-refresh v-if="pageModuls.length > 0" v-model="refreshing" @refresh="onRefresh">
    <van-list v-model:loading="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
      <div class="infoa" v-for="user in userlist">
        <div class="infoc">

          <div style="width: 100%; float: left">
            <div id="hobby">
              <!-- 爱好 -->
              <div>
                <div class="hobbyinfo">

                  <!-- <div class="personalinfo" @click="
                    visitClick(user.openId),
                    otherInfoClick(
                      user.openId,
                      user.userName,
                      user.nickname,
                      0
                    )
                  "> -->
                  <div class="personalinfo">
                    【昵称】
                    <!-- <span style="margin-right: 3px; color: #2c3e50" @click="
                      visitClick(user.openId),
                      otherInfoClick(
                        user.openId,
                        user.userName,
                        user.nickname,
                        0
                      )
                    ">{{ user.userName }}
                    </span> -->
                    <span style="margin-right: 3px; color: #2c3e50">{{ user.userName }}
                    </span>
                    【爱好】
                    <span v-if="user.hobbyType == 0" style="color: #2c3e50">爱旅游旅行</span>
                    <span v-else-if="user.hobbyType == 1" style="color: #2c3e50">爱唱歌文艺</span>
                    <span v-else-if="user.hobbyType == 2" style="color: #2c3e50">爱厨艺美食</span>
                    <span v-else-if="user.hobbyType == 3" style="color: #2c3e50">爱自愈自乐</span>
                    <span v-else-if="user.hobbyType == 4" style="color: #2c3e50">爱追剧追星</span>
                    <span v-else style="color: #2c3e50">爱国外移民</span>
                    【性格】
                    <span v-if="user.natureType == 0" style="color: #2c3e50">内敛怀旧型</span>
                    <span v-else-if="user.natureType == 1" style="color: #2c3e50">激情奔放型</span>
                    <span v-else-if="user.natureType == 2" style="color: #2c3e50">店商自信型</span>
                    <span v-else-if="user.natureType == 3" style="color: #2c3e50">宁缺毋滥型</span>
                    <span v-else-if="user.natureType == 4" style="color: #2c3e50">忘年随缘型</span>
                    <span v-else style="color: #2c3e50">夫唱妇随型</span>
                  </div>
                </div>
                <div class="youth">
                  <!-- <p>
                    <span class="personalinfo">昵称</span>
                    <span>{{
                      user.userName
                    }}</span>
                  </p> -->
                  <p>
                    <span class="personalinfo">性别</span>
                    <span v-if="user.genderType == 0">男</span>
                    <span v-else>女</span>
                  </p>
                  <p>
                    <span class="personalinfo">年龄</span>
                    <span>{{ user.age }}</span>
                  </p>
                </div>
                <div class="youth">
                  <p>
                    <span class="personalinfo">子女</span>
                    <span>{{ user.maleCount }}男{{ user.femaleCount }}女</span>
                  </p>
                  <p>
                    <span class="personalinfo">职业</span>
                    <span>{{ user.job }}</span>
                  </p>
                  <p>
                    <span class="personalinfo">住房</span>
                    <span v-if="user.housingType == 1">有</span>
                    <span v-else>无</span>
                  </p>
                </div>
                <!-- <div class="expect" @click="
                  visitClick(user.openId),
                  otherInfoClick(
                    user.openId,
                    user.userName,
                    user.nickname,
                    0
                  )
                "> -->
                <div class="expect">
                  <p>
                    <span class="personalinfo">择偶期望：</span><span>{{ user.hope }}</span>
                  </p>
                </div>

                <div class="expressionContentimg" @click="visitClick(user.openId)
    ">
                  <img :src="user.publishs[0].url" style="width: 95%" alt=""
                    @click="imagePreview(user.publishs, index)" />
                  <!-- <div v-if="user.memberState == 0" class="memberState" @click="imagePreview(user.publishs, index)">
                    <span style="font-weight: 900; font-size: 20px;font-weight: bold; color: #ffffff">非认证会员</span>
                  </div> -->
                </div>
                <div class="Video" style="height: 31px; width: 100%; display: flex">
                  <div style="width: 100%; margin: 8px;font-weight: bold;">
                    <van-tag class="  " color="#25cbc9" plain v-if="user.memberState == 0">未认证</van-tag>
                    <van-tag class="auth" color="#25cbc9" plain v-else-if="user.memberState == 1">认证会员</van-tag>
                    <van-tag class="auth" color="#25cbc9" plain v-else>认证会员</van-tag>
                  </div>
                  <div class="communication" @click="chatclick(
    user.userName,
    user.headimgUrl[0].url,
    user.openId,
    user.memberState,
    user.phone,
    user.sysUserType
  )
    ">
                    <van-image round width="1rem" height="2rem" :src="require('../assets/imags/subject.jpg')" />
                  </div>

                  <div @click="chatclick(
    user.userName,
    user.headimgUrl[0].url,
    user.openId,
    user.memberState,
    user.phone,
    user.sysUserType
  )
    " style="color: darkgray;width: 100%;">
                    <p style="text-align: initial; margin: 5px; font-weight: bold;">向TA私聊</p>
                  </div>

                  <div class="communication" @click="visitClick(user.openId),
    otherInfoClick(
      user.openId,
      user.userName,
      user.nickname,
      user.sysUserType
    )
    ">
                    <van-image round width="1rem" height="2rem" :src="require('../assets/imags/top.jpg')" />
                  </div>

                  <div @click="visitClick(user.openId),
    otherInfoClick(
      user.openId,
      user.userName,
      user.nickname,
      user.sysUserType
    )
    " style="color: darkgray; width: 100%;">
                    <p style="text-align: initial; margin: 5px; font-weight: bold;">TA的动态</p>
                  </div>

                  <div class="communication" @click="followClick(
    user.openId,
    user,
    user.isFollow,
    0,
    user.memberState
  )
    ">
                    <van-image round width="1rem" height="2rem" :src="require('../assets/imags/FocusonTA.jpg')" />
                  </div>
                  <div @click="followClick(
    user.openId,
    user,
    user.isFollow,
    0,
    user.memberState
  )
    " style="color: red; width: 100%;" v-if="user.isFollow == false">
                    <p style="text-align: initial; margin: 5px;font-weight: bold;">关注TA</p>
                  </div>
                  <div @click="followClick(
    user.openId,
    user,
    user.isFollow,
    0,
    user.memberState
  )
    " style="color: green; width: 100%;" v-else>
                    <p style="text-align: initial; margin: 5px;font-weight: bold;">已关注</p>
                  </div>
                </div>
                <div class="time">
                  <!-- <span>{{ user.carteTime }}</span> -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="personalopinion">
          <div class="personalopinioninfo">
            <van-icon name="like-o" />
            <span>说明: </span>
            <span>{{ user.subject }}</span>
          </div>
        </div>
      </div>
      <van-empty v-if="userlist.length == 0"
        image="https://marrypicture.guisuzhenhun.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230305022357.jpg"
        image-size="80" description="" />
    </van-list>
  </van-pull-refresh>
  <van-empty v-else
    image="https://marrypicture.guisuzhenhun.com/%E5%BE%AE%E4%BF%A1%E5%9B%BE%E7%89%87_20230305022357.jpg"
    image-size="80" description="加载中..." />

  <!-- 返回顶部按钮 -->
  <div class="back-top back-btn" v-if="userlist.length > 0">
    <van-icon style="left: 83%; top: 73%; background-color: 0 !important" v-if="btnFlag" class="back-btn"
      @click="backTop" ref="box" name="apps-o" />
    <div class="back-node">
      回到顶部
    </div>
  </div>
  <div style="width: 100%; height: 100px" @click="show = true">
    <van-dialog v-model="show" title="标题" show-cancel-button>
      <img src="https://img01.yzcdn.cn/vant/apple-3.jpg" />
    </van-dialog>
  </div>
  <van-overlay :show="showService" @click="showService = false">
    <div class="wrapper">
      <div class="block">
        <p style="font-size: 20px">联系客服</p>
        <van-button type="default" @click="callPhone('4000911696')">客服电话</van-button>
        <van-button type="primary" @click="weChatservice()">微信客服</van-button>
      </div>
    </div>
  </van-overlay>
</template>

<script>
import { ref } from "vue";
import { objpositions } from "@/common/data/positions.js"; //职位信息
import { ImagePreview } from "vant";
import {
  CarouselOrPageGridOrCommPage,
  GetUsers,
  Follow,
  Visit,
  GetUserInfo,
  Access_token,
  Refresh_token,
  Userinfo,
  WeChatAuth,
  UpdateReadState, GetChatRecords, GetChatRecordsTaskRun, IsChatRecords, UpdateIsClose
} from "@/api/index"; // 轮播栏目-页面宫格-通讯页面

import {
  SetToken,
  GetToken,
  SetLocalStorage,
  GetLocalStorage,
  SetSessionStorage,
  GetSessionStorage,
  RemoveToken,
  ClearLocalStorage,
} from "@/common/auth";

import vConsole from "vconsole";
//当前微信授权openId
var myopenId = GetToken("myopenId");

export default {
  name: "HelloWorld",
  components: {},
  data() {

    //20230217 搜对象
    const maleCount = ref("");
    const maleValue = ref(-1);
    const showmale = ref(false);
    const maleColumns = [
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
    ];

    const onConfirmmale = (value) => {
      maleCount.value = value;
      maleValue.value = value;
      showmale.value = false;
    };

    const femaleCount = ref("");
    const femaleValue = ref(-1);
    const showfemale = ref(false);

    const onConfirmfemale = (value) => {
      femaleCount.value = value;
      femaleValue.value = value;
      showfemale.value = false;
    };

    // 爱好分类
    const hobbyType = ref("");
    const hobbyValue = ref(-1);
    const showHobby = ref(false);
    const hobbyColumns = [
      "爱旅游旅行",
      "爱唱歌文艺",
      "爱厨艺美食",
      "爱自愈自乐",
      "爱追剧追星",
      "爱国外移民",
    ];

    const onConfirmHobby = (value, index) => {
      hobbyType.value = value;
      hobbyValue.value = index;

      showHobby.value = false;
    };

    // 年龄
    const age = ref("");
    const age1 = ref("");
    const showAge = ref(false);
    const showAge1 = ref(false);
    const ageColumns = [
      20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80
    ];

    const onConfirmAge = (value) => {
      age.value = value;
      showAge.value = false;
    };
    const onConfirmAge1 = (value) => {
      age1.value = value;
      showAge1.value = false;
    };

    // 性格分类
    const natureType = ref("");
    const natureValue = ref(-1);
    const shownatureType = ref(false);
    const natureTypeColumns = [
      "内敛怀旧型",
      "激情奔放型",
      "店商自信型",
      "宁缺毋滥型",
      "忘年随缘型",
      "夫唱妇随型",
    ];

    const onConfirmnatureType = (value, index) => {
      natureType.value = value;
      natureValue.value = index;
      shownatureType.value = false;
    };

    // 职位
    const position = ref("");
    const positionjob = ref("");
    const showposition = ref(false);
    const onConfirmaposition = (positionValues) => {
      showposition.value = false;
      positionjob.value = positionValues[0].name;
      position.value = positionValues[1].name;
    };
    //end

    const activetabbar = ref(0);
    const users = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const refreshing = ref(false);
    const onLoad = () => {
      // 异步更新数据
      setTimeout(() => {
        if (refreshing.value) {
          users.value = [];
          refreshing.value = false;
        }
        for (let i = 0; i < this.userlist.length; i++) {
          users.value.push(users.value.length + 1);
        }

        // 加载状态结束
        loading.value = false;
        // 数据全部加载完成
        if (users.value.length >= 0) {
          finished.value = true; // 没有更多的了
        }
      }, 2000);
    };
    const onRefresh = () => {
      // 清空列表数据
      finished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      this.getUsers(myopenId);
      onLoad();
    };

    //提交搜索对象
    const onSubmitSearch = () => {
      this.searchobj();
    };

    const privatelist = ref([]);
    const privateloading = ref(false);
    const privatefinished = ref(false);
    const privaterefreshing = ref(false);
    const privateonLoad = () => {
      setTimeout(() => {
        if (privaterefreshing.value) {
          privatelist.value = [];
          privaterefreshing.value = false;
        }

        for (let i = 0; i < 10; i++) {
          privatelist.value.push(privatelist.value.length + 1);
        }
        privateloading.value = false;

        if (privatelist.value.length >= 40) {
          privatefinished.value = true;
        }
      }, 1000);
    };
    const privateonRefresh = () => {
      // 清空列表数据
      privatefinished.value = false;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      privateloading.value = true;
      onLoad();
    };

    return {

      privatelist,
      privateonLoad,
      privateloading,
      privatefinished,
      privateonRefresh,
      privaterefreshing,
      chatRecords: [],

      privateshow: false,//是否弹出私聊信息列表
      //对象搜索
      //男
      onSubmitSearch,
      onConfirmmale,
      maleColumns,
      maleCount,
      maleValue,
      showmale,
      //职位
      position,
      positionjob,
      showposition,
      onConfirmaposition,
      onConfirmfemale,
      //职位信息
      positions: {
        province_list: {},
        city_list: {},
        county_list: {},
      },

      // 爱好分类
      hobbyType,
      hobbyValue,
      showHobby,
      hobbyColumns,
      onConfirmHobby,
      // 性格分类
      natureType,
      natureValue,
      shownatureType,
      natureTypeColumns,
      onConfirmnatureType,
      // 年龄
      age,
      age1,
      showAge,
      showAge1,
      ageColumns,
      onConfirmAge,
      onConfirmAge1,
      //住房
      housingType: "",
      userName: "",
      showfemale,
      femaleCount,
      femaleValue,
      //end

      myopenId: myopenId, //当前自己openid
      show: false,
      objshow: false,
      users,
      onLoad,
      loading,
      finished,
      onRefresh,
      refreshing,
      activetabbar,
      showService: false,
      // count: 0,
      imgLists: [],
      pageModuls: [], //页面宫格
      userlist: [],
      btnFlag: true, // 按钮的显示隐藏状态
      scrollTop: 0, // 距离顶部的位置
      followOFcancel: false,
      city: "", //城市
      link: "", //链接
      maleFemale: 0,
      memberState: GetLocalStorage("memberState"), //会员状态
      deleteState: 0, //是否删除状态
      isCompleteInfoState: 0, //是否注册

      //access_token刷新信息
      access_tokeninfo: {
        access_token: "",
        expires_in: 0,
        refresh_token: "",
        openid: "",
        scope: "",
      },
      //刷新信息
      refresh_tokeninfo: {
        access_token: "",
        expires_in: 0,
        refresh_token: "",
        openid: "",
        scope: "",
      },
      //微信授权后用户信息
      userinfo: {
        openid: "",
        nickname: "",
        sex: 1,
        province: "",
        city: "",
        country: "",
        headimgUrl: "",
        privilege: [],
        unionid: "",
      },
      //授权信息
      wecharAuthinfo: {
        errcode: 0,
        errmsg: "",
      },
      imgPreview: [], //预览图片
      interval: 0,//定时实例
      videoState: 0,//视频状态
      audioState: 0,//音频状态
      isPopped: true,//是否弹窗
      webSocket: null,
    };
  },

  //首次加载
  created() {
    // new vConsole(); //test
    // this.getChatRecords();
    // SetSessionStorage("replyNot", false)
    this.initWebSocket();
    this.isChatRecords();
    if (GetToken("myopenId") != undefined) {
      // this.getUserInfo(GetToken("myopenId"));
      this.interval = setInterval(() => {
        myopenId = GetToken("myopenId");
        if (this.isCompleteInfoState == 1)
          this.getChatRecordsTaskRun(GetToken("myopenId"));
      }, 7000); //每7秒执行1次
    }

    //职位信息
    let position = objpositions();
    this.positions.province_list = position.province_list;
    this.positions.city_list = position.city_list;
    this.positions.county_list = position.county_list;

    let openid = this.$route.query.openid;
    let refreshtoken = this.$route.query.refreshtoken;
    if (openid != undefined && refreshtoken != undefined) {
      SetToken("myopenId", openid);
      SetToken("refresh_token", refreshtoken);
    }

    this.isCompleteInfoState = GetLocalStorage("isCompleteInfoState");
    let refresh_token = GetToken("refresh_token");

    if (this.isCompleteInfoState == 1) {
      // if (refresh_token != undefined) {
      //   console.log("刷新微信用户信息");
      //   this.refresh_token(refresh_token);//正式
      // }
      //测试
      this.getUserInfo(GetToken("myopenId"));
      //console.log("carouselOrPageGridOrCommPage03 未授权后操作");
      this.carouselOrPageGridOrCommPage(GetToken("myopenId"));
    } else {
      // this.refresh_token(refresh_token);//正式
      //测试
      this.getUserInfo(GetToken("myopenId"));
      //console.log("carouselOrPageGridOrCommPage04");
      this.carouselOrPageGridOrCommPage(GetToken("myopenId"));
    }

    let searchState = GetLocalStorage("searchState");
    //console.log("searchState : " + searchState);
    let codeauth = this.$route.query.code;
    //console.log("codeauth--------------------: ", codeauth);

    if (
      (codeauth == undefined && searchState == 0) ||
      (codeauth == undefined && searchState == undefined)
    ) {
      //console.log("carouselOrPageGridOrCommPage00 未授权前执行");
    }

    if (codeauth != undefined && refresh_token == undefined) {
      //微信授权
      this.WeChatAuthorizationPage();
    }
    // if (refresh_token != undefined) {
    //   console.log("刷新微信用户信息");
    //   this.refresh_token(refresh_token, GetToken("myopenId"));
    // }
  },

  methods: {

    //初始化weosocket
    initWebSocket() {

      var rmyOpenId = GetToken("myopenId");
      if (rmyOpenId != undefined) {
        //const wsuri = `wss://localhost:5003/api/ChatRecords/CreateWebSocket?myOpenIdToCustomeropenId=${rmyOpenId}`;
        const wsuri = `ws://119.23.210.247:5003/api/ChatRecords/CreateWebSocket?myOpenIdToCustomeropenId=${rmyOpenId}`;
        //const wsuri = `ws://www.guisuzhenhun.com/sys/api/ChatRecords/CreateWebSocket?myOpenIdToCustomeropenId=${rmyOpenId}`;
        //const wsuri = `ws://www.guisuzhenhun.com/myOpenIdToCustomeropenId`;
        try {
          this.webSocket = new WebSocket(wsuri);
          this.webSocket.onmessage = this.websocketonmessage;
          this.webSocket.onopen = this.websocketonopen;
          this.webSocket.onerror = this.websocketonerror;
          this.webSocket.onclose = this.websocketclose;
        }
        catch (e) {

        }
      }
    },
    /*年龄1*/
    handleClearClick1() {
      this.age = "";
    },
    /*年龄范围2*/
    handleClearClick2() {
      this.age1 = "";
    },

    /*子女：男*/
    maleCountClick() {
      this.maleCount = "";
    },
    /*子女：女*/
    femaleCountClick() {
      this.femaleCount = "";
    },

    /*职位选择*/
    positionClick() {
      this.position = "";
    },

    /*爱好分类*/
    hobbyTypeClick() {
      this.hobbyType = "";
    },

    /*性格分类*/
    natureTypeClick() {
      this.natureType = "";
    },

    // 连接建立时触发
    websocketonopen() {
      //console.log("连接建立")
      // setInterval(() => {
      //   if (this.webSocket.readyState === WebSocket.CLOSED) {
      //     this.initWebSocket();
      //   }
      // }, 3500)
    },

    // 通信发生错误时触发
    websocketonerror(e) {
      //console.log("出现错误" + e);
    },
    // 客户端接收服务端数据时触发
    websocketonmessage(e) {
      if (e.data.includes("_正在加入聊天")) {
        //console.log(e.data)
      }
      else {
        this.getChatRecords();
        this.privateshow = true;
      }
    },
    // 连接关闭时触发
    websocketclose(e) {
      //console.log("断开连接", e);
    },
    //关闭通信移除对象
    // closeWebSocket() {
    //   const params = {
    //     // myOpenIdToCustomeropenId: GetToken("myopenId"),
    //     myOpenIdToCustomeropenId: GetToken("myopenId"),
    //     text: "xxx",
    //     isclose: "xxx"
    //   }
    //   SendAsync(params).then((res) => {
    //     console.log(res)
    //   })
    // },

    async isChatRecords() {
      let params = {
        openId: myopenId,
      };
      let res = await IsChatRecords(params);
      if (res.code == 200) {
      }
      else {
        this.privateshow = false;
        SetSessionStorage("replyNot", false)
      }
    },

    //关闭聊天窗口列表
    cancelprivateshow() {
      // clearInterval(this.interval); //停止监听
      SetSessionStorage("replyNot", false)
      this.privateshow = false;
      this.SupdateIsClose();
    },

    //弹窗私聊列表
    //获取私聊列表
    async getChatRecords() {
      let params = {
        openId: GetToken("myopenId"),
      };
      let result = await GetChatRecords(params);
      if (result.code == 200) {
        this.chatRecords = result.data;
      }
      else {
        this.privateshow = false;
        // SetSessionStorage("replyNot", false)
      }
    },
    //监听新私聊消息
    async getChatRecordsTaskRun(myopenId) {
      let params = {
        openId: myopenId,
      };
      const replyNot = GetSessionStorage("replyNot");
      let result = await GetChatRecordsTaskRun(params);
      if (result.code == 200) {
        this.privateshow = true;
        SetSessionStorage("replyNot", true);
        this.getChatRecords();
      }
      else {
        if (replyNot == null || replyNot == undefined || replyNot == "false") {
          this.privateshow = false;
        }
        else {
          // this.getChatRecords();
          if (replyNot == "true") {
            this.privateshow = true;
            this.getChatRecords();
          }
          else {
            this.privateshow = false;
          }
        }
      }
    },

    //查看状态更新
    async updateReadState(copenId, id, username, headimgUrl, currentPhone, sysUserType) {
      if (this.deleteState == 1) {
        this.$toast.fail("您的账号已被限制,请联系客服处理");
        return;
      }
      let params = {
        copenId: copenId,
        readNoRead: 1,
        id: id,
      };
      let result = await UpdateReadState(params);
      if (result.code == 200 || 201) {
        //console.log(result.message);

        // if (copenId == GetLocalStorage("customeropenId")) {
        SetLocalStorage("customeropenId", copenId);
        // }

        SetLocalStorage("customerheadimgUrl", headimgUrl);
        SetLocalStorage("customerPhone", currentPhone);
        SetLocalStorage("replyNotChatId", id);
        SetLocalStorage("sysUserType", sysUserType);
        SetLocalStorage("customernickname", username);
        clearInterval(this.interval); //停止监听

        this.$router.push({
          path: "/chat",
        });
      }
    },
    //end 

    async SupdateIsClose() {
      let params = { customerOpenId: GetToken("myopenId") }
      await UpdateIsClose(params).then(res => {
        if (res.code == 200) {
          this.$toast.success("已关闭")
        }
      })
    },

    //预览图片
    imagePreview(imgs, index) {
      this.imgPreview = [];
      imgs.forEach((img) => {
        this.imgPreview.push(img.url);
      });
      ImagePreview({
        images: this.imgPreview,
        showIndex: true,
        loop: false,
        startPosition: index,
      });
    },

    //网页授权
    WeChatAuthorizationPage() {
      //---------------------------------------------------- 微信授权
      let codeauth = this.$route.query.code;
      //console.log("codeauth--------------------: ", codeauth);
      if (codeauth != undefined) {
        // SetToken("codeauth", codeauth);
      }
      let refresh_token = GetToken("refresh_token");
      let access_token = GetToken("access_token");

      if (codeauth == undefined) {
        this.authorize();
        return;
      }

      if (refresh_token == undefined) {
        this.access_token(codeauth);
      } else {
        let code = this.weChatAuthinfo(access_token, myopenId);
        if (code != 200) {
          this.access_token(codeauth);
          // SetToken("codeauth", codeauth);
        }
      }

      // if (myopenId != undefined) {
      // this.getUserInfo(myopenId);
      // }
    },

    //轮播栏目-页面宫格-通讯页面
    async carouselOrPageGridOrCommPage(myopenId) {
      this.imgLists = [];
      this.pageModuls = [];
      let params = {
        openId: myopenId,
      };
      let letresult = await CarouselOrPageGridOrCommPage(params);
      if (letresult.code == 200) {
        // letresult.data.forEach((itme) => {
        //   if (itme.modulType == 0) {
        //     this.imgLists.push(itme);
        //   } else {
        //     this.pageModuls.push(itme);
        //   }
        // });
        let imgList = [];
        let pageModul = [];

        for (let index = 0; index < letresult.data.length; index++) {
          const itme = letresult.data[index];
          if (itme.modulType == 0) {
            imgList.push(itme);
          } else {
            pageModul.push(itme);
          }
        }
        this.imgLists = imgList;
        this.pageModuls = pageModul;
        // this.pageModuls.slice(0, 11);
      } else {
        this.imgLists = [];
        this.pageModuls = [];
      }
    },

    // 获取首页用户信息
    async getUsers(myopenId) {
      if (this.age != "" && this.age < 20) {
        this.age = "";
        this.$toast.fail("年龄不能输入小于20");
        return;
      }
      if (this.age != "" && this.age > this.age1) {
        this.age1 = "";
        this.$toast.fail("年龄开始区间不能大于结束区间");
        return;
      }
      this.userlist = [];
      //关注0
      let params = {
        myOpenId: myopenId,
        maleFemale: this.maleFemale,
        city: this.city,
      };
      let result = await GetUsers(params);
      if (result.data != "") {
        setTimeout(() => {
          result.data.forEach((itme) => {
            this.userlist.push(itme);
          });
        }, 2000)
        //console.log(this.userlist, "获取首页用户信息");
      } else {
        this.userlist = [];
      }
    },

    //获取用户信息
    async getUserInfo(myopenId) {
      //console.log("获取用户信息.....");
      let params = {
        myOpenId: myopenId,
      };
      if (params.myOpenId != undefined) {
        let result = await GetUserInfo(params);
        if (result.code == 200) {
          this.isCompleteInfoState = result.data.user.isCompleteInfoState;
          if (this.isCompleteInfoState == 1) {
            this.city = result.data.user.city;
          }
          else {
            this.city = "";
          }
          myopenId = GetToken("myopenId");
          SetLocalStorage("userName", result.data.user.userName);
          this.maleFemale = result.data.user.maleFemale;
          this.memberState = result.data.user.memberState;
          this.deleteState = result.data.user.deleteState;

          SetLocalStorage("memberState", this.memberState);
          SetLocalStorage("isCompleteInfoState", this.isCompleteInfoState);
          SetLocalStorage("fullName", result.data.user.fullName);
          SetLocalStorage("myphone", result.data.user.phone);
          SetLocalStorage("iDCard", result.data.user.iDCard);
          SetLocalStorage("headimgUrl", result.data.user.pagePhoto);
          SetLocalStorage("auditState", result.data.user.auditState);
          //console.log("getUserInfo00...");
          this.getUsers(myopenId);
        } else {
          RemoveToken("ClearLocalStorage01");
          ClearLocalStorage();
          // RemoveToken("myopenId");
          // RemoveToken("refresh_token");
          //console.log("ClearLocalStorage00...");
          //console.log("getUserInfo01...");
          this.getUsers(myopenId);
        }
      } else {
        //console.log("ClearLocalStorage02...");
        ClearLocalStorage();
        // RemoveToken("myopenId");
        // RemoveToken("refresh_token");
        //console.log("getUserInfo02...");
        this.getUsers(myopenId);
      }
    },

    //第一步：用户同意授权，获取code
    async authorize() {
      console.log(
        "第一步：用户同意授权，获取ppid=wx8479ffd35d819d9f&redirect_uri=https://www.guisuzhenhun.com"
      );

      let appid = config.appId;
      let parameter = config.parameter;
      let redirect_uri = config.baseUrl;
      window.location.href = `${config.authorizeUrl}?appid=${appid}&redirect_uri=${redirect_uri}&${parameter}`;
    },

    //--------------------------------------------------------------微信网页授权---------------------------------------------------------------->>>
    //第二步：通过 code 换取网页授权access_token
    async access_token(code) {
      let params = {
        code: code,
      };
      let result = await Access_token(params);
      if (result.code == 200) {
        this.access_tokeninfo = result.data;
        SetToken("refresh_token", result.data.refresh_token);
        this.refresh_token(result.data.refresh_token);
        console.log(
          "第二步：通过 code 换取网页授权access_token:",
          this.access_tokeninfo
        );
        return;
        // this.carouselOrPageGridOrCommPage(myopenId);
      } else {
        this.authorize();
      }
    },

    //第三步：刷新access_token（如果需要）
    async refresh_token(refresh_token) {
      this.pageModuls = [];
      let params = {
        refresh_token: refresh_token,
      };
      let result = await Refresh_token(params);
      if (result.code == 200) {
        this.refresh_tokeninfo = result.data;
        console.log(
          "第三步：刷新refresh_token（如果需要）:",
          this.refresh_tokeninfo
        );
        SetToken("refresh_token", result.data.refresh_token);
        SetToken("access_token", result.data.access_token);
        SetToken("myopenId", result.data.openid);
        this.getUserInfo(result.data.openid);
        this.carouselOrPageGridOrCommPage(result.data.openid);

        this.userInfo(result.data.access_token, result.data.openid);
        return;
      } else {
        this.getUserInfo(GetToken("myopenId"));
        this.carouselOrPageGridOrCommPage(GetToken("myopenId"));
        // this.authorize();
      }
    },

    //第四步拉取用户信息(需 scope 为 snsapi_userinfo)
    async userInfo(access_token, openid) {
      let params = {
        access_token: access_token,
        openid: openid,
      };
      let result = await Userinfo(params);
      if (result.code == 200) {
        this.userinfo = result.data.obj;
        console.log(
          "第四步拉取用户信息(需 scope 为 snsapi_userinfo):",
          this.userinfo
        );
        SetLocalStorage("nickname", this.userinfo.nickname);
        // SetLocalStorage("headimgUrl", this.userinfo.headimgurl);
        SetLocalStorage("openId", this.userinfo.openid);
        SetToken("h5token", result.data.token);
        SetToken("myopenId", this.userinfo.openid);

        if (this.isCompleteInfoState == 0) {
          //console.log("carouselOrPageGridOrCommPage01 未注册执行");
          // this.carouselOrPageGridOrCommPage(this.userinfo.openid)
          // this.getUserInfo(this.userinfo.openid);
        } else {
          //console.log("carouselOrPageGridOrCommPage02 未授权后执行");

          // this.carouselOrPageGridOrCommPage(this.userinfo.openid);

          // this.getUserInfo(this.userinfo.openid);
        }
        return;
      } else {
        SetToken("access_token", undefined);
        SetToken("refresh_token", undefined);
        this.authorize();
      }
    },

    //检验授权凭证（access_token）是否有效
    async weChatAuthinfo(access_token, openid) {
      let params = {
        access_token: access_token,
        openid: openid,
      };
      let result = await WeChatAuth(params);
      if (result.code == 200) {
        this.wecharAuth = result.data;
        console.log("检验授权凭证（access_token）是否有效:", this.wecharAuth);
        this.userInfo(access_token, openid);
        return result.code;
      }
    },

    //页面模块不同事件
    differentEvent(deventname, link) {

      if (this.deleteState == 1 && deventname != "myclick" && this.deleteState == 1 && deventname != "servseclick") {
        this.$toast.fail("您的账号已被限制,请联系客服处理");
        return;
      }
      else if ((this.memberState == 0 || this.memberState == 4) && deventname != "myclick"
        && deventname != "servseclick" && deventname != "lovetravel" && deventname != "silhouette") {
        this.$dialog
          .confirm({
            title: "需要认证",
            message: "请先点击“我要认证”再使用本功能",
          })
          .then(() => {
            this.authclick();
          });
        return;
      }
      else if (deventname == "searchobjclick") {
        this.searchobjclick();
        return;
      }
      else if (deventname == "silhouette" && link != "") {
        window.location.href = link;
        return;
      } else if (deventname == "servseclick" && link != "") {
        this.showService = true;
        this.link = link;
        return;
      }
      // this.objshow = true;
      let refresh_token = GetToken("refresh_token");
      let myoId = GetToken("myopenId");
      if (
        refresh_token == undefined || (myoId == undefined && deventname != "searchobjclick")
      ) {
        // this.$dialog
        //   .confirm({
        //     title: "未授权登录",
        //     message: "请授权注册",
        //     confirmButtonColor: "red",
        //   })
        //   .then(() => {
        //     console.log("点击了确认");
        //     if (refresh_token == null || refresh_token == undefined) {
        //       this.authorize();
        //       return;
        //     }
        //   })
        //   .catch(() => {
        //     console.log("点击了取消");
        //     return;
        //   });
        this.unregistered()
        return;
      } else {
        if (deventname == "authclick") {
          this.authclick();
          return;
        }
        // else if (deventname == "searchobjclick") {
        //   this.searchobjclick();
        //   return;
        // }
        else if (deventname == "releaseclick") {
          this.releaseclick();
          return;
        } else if (deventname == "myfollowclick") {
          this.myfollowclick("我关注谁");
          return;
        } else if (deventname == "whofollowclick") {
          this.whofollowclick("谁关注我");
          return;
        } else if (deventname == "rechargeclick") {
          this.rechargeclick();
          return;
        } else if (deventname == "registerclick") {
          this.registerClick();
          return;
        } else if (deventname == "myclick") {
          this.myclick();
          return;
        } else if (deventname == "lovetravel") {
          this.$router.push({ path: "lovetravel" });
          return;
        }
      }
    },

    //未注册提示
    unregistered() {
      let refresh_token = GetToken("refresh_token");
      let myoId = GetToken("myopenId");
      if (
        refresh_token == undefined ||
        (myoId == undefined && deventname != "searchobjclick")
      ) {
        this.$dialog
          .confirm({
            title: "未授权登录",
            message: "请授权注册",
            confirmButtonColor: "red",
          })
          .then(() => {
            //console.log("点击了确认");
            if (refresh_token == null || refresh_token == undefined) {
              this.authorize();
              return;
            }
          })
          .catch(() => {
            //console.log("点击了取消");
            return;
          });
      }
    },

    //微信客服
    weChatservice() {
      window.location.href = this.link;
    },

    //关注
    async followClick(whoOpenId, user, isfollow, sysUserType, memberState) {

      if (myopenId == undefined) {
        this.getAuthorizeTips();
        return;
      }
      if (memberState == 0) {
        this.$toast.fail("该用户未认证");
        return;
      }
      if (this.deleteState == 1) {
        this.$toast.fail("您的账号已被限制,请联系客服处理");
        return;
      }
      if (this.memberState == 0 || this.memberState == 1 || this.memberState == 4) {
        this.$dialog
          .confirm({
            title: "需要认证",
            message: "请先点击“我要认证”再使用本功能",
          })
          .then(() => {
            this.authclick();
          });
        return;
      }
      // if (sysUserType == 1) {
      //   this.sysUserType();
      //   return;
      // }
      if (myopenId == whoOpenId) {
        this.$toast.fail("不能关注自己");
        return;
      }
      for (let i = 0; i < this.userlist.length; i++) {
        if (this.userlist[i].openId == whoOpenId) {
          user.followId = this.userlist[i].followId;
        }
      }

      let params = {
        whoOpenId: whoOpenId,
        myOpenId: isfollow == false ? myopenId : "", //"ozp3Y6TLGjNewGjKtJEXGzbIh9eY"
        Id: isfollow == false ? 0 : user.followId,
      };

      let result = await Follow(params);
      if (result.code == 100 && result.data != "") {
        for (let i = 0; i < this.userlist.length; i++) {
          if (
            this.userlist[i].openId == whoOpenId &&
            this.userlist[i].isFollow == false
          ) {
            this.userlist[i].isFollow = true;
            this.userlist[i].followId = result.data;
            user.followId = result.data;
            return;
          }
        }
      } else {
        if (result.code == 100 || result.code == 200) {
          for (let i = 0; i < this.userlist.length; i++) {
            if (
              this.userlist[i].openId == whoOpenId &&
              this.userlist[i].isFollow == true
            ) {
              this.userlist[i].isFollow = false;
              return;
            } else if (
              this.userlist[i].openId == whoOpenId &&
              this.userlist[i].isFollow == false
            ) {
              this.userlist[i].isFollow = true;
              return;
            }
          }
        }
      }
    },

    //累计浏览量
    async visitClick(openId) {
      let params = {
        openId: openId,
      };
      let result = await Visit(params);
      if (result.code == 200) {
        this.userlist.forEach((user) => {
          if (user.openId == openId) {
            user.browseCount = user.browseCount + 1;
          }
        })
      }
      //console.log(result, "累计浏览量...");
    },

    // 点击图片回到顶部方法，加计时器是为了过渡顺滑
    backTop() {
      const timer = setInterval(() => {
        const ispeed = Math.floor(-this.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          this.scrollTop + ispeed;
        if (this.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    // 为了计算距离顶部的高度，当高度大于60显示回顶部图标，小于60则隐藏
    scrollToTop() {
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      this.scrollTop = scrollTop;
      if (this.scrollTop > 200) {
        // this.btnFlag = true;
      } else {
        // this.btnFlag = false;
      }
    },

    // 谁关注我
    whofollowclick(flabel) {
      this.$router.push({ path: "/follow/who" });
    },
    //我关注谁
    myfollowclick(flabel) {
      this.$router.push({ path: "/follow/my" });
    },
    // 搜索对象
    searchobjclick() {
      this.objshow = true;
      // this.$router.push({ path: "searchobj" });
    },
    // 我要充值
    rechargeclick() {
      if (this.memberState == 0 || this.memberState == 1 || this.memberState == 4) {
        this.$dialog
          .confirm({
            title: "需要认证",
            message: "请先点击“我要认证”再使用本功能",
          })
          .then(() => {
            this.authclick();
          });
        return;
      }
      this.$router.push({ path: "recharge" });
    },
    // 其他个人发布消息
    otherInfoClick(whoOpenId, userName, nickname, sysUserType) {
      SetLocalStorage("isreload", false)
      if (userName == null) {
        userName = nickname;
      }
      // if (sysUserType == 1) {
      //   this.sysUserType();
      //   return;
      // }
      this.$router.push({
        path: "/detailpage/" + whoOpenId + "/" + myopenId + "/" + userName,
      });
    },
    // 注册登录
    registerClick() {
      this.$router.push({ path: "/register" });
    },

    // 我要发布
    releaseclick() {
      if (this.memberState == 0 || this.memberState == 1) {
        this.$dialog
          .confirm({
            title: "需要认证",
            message: "请先点击“我要认证”再使用本功能",
          })
          .then(() => {
            this.authclick();
          });
        return;
      }
      this.$router.push({ path: "release" });
    },
    // 我要认证
    authclick() {
      if (
        this.isCompleteInfoState == 0 ||
        this.isCompleteInfoState == undefined
      ) {
        this.$dialog
          .confirm({
            title: "需要注册",
            message: "请先点击“注册”再使用本功能",
          })
          .then(() => {
            this.registerClick();
          });
        return;
      }
      if (this.memberState == 2) {
        this.$toast.success("已认证");
        return;
      }
      this.$router.push({ path: "auth" });
    },
    //授权提示
    getAuthorizeTips() {
      this.$dialog
        .confirm({
          title: "需要授权认证",
          message: "请先授权",
        })
        .then(() => {
          this.authorize();
        });
    },

    // 我的
    myclick() {
      this.$router.push({ path: "my" });
    },
    // 拨打电话
    callPhone(phoneNumber) {
      window.location.href = "tel:" + phoneNumber;
    },

    // 向TA私聊
    async chatclick(
      userName,
      headimg,
      openId,
      memberState,
      phone,
      sysUserType,
      audioRtcId
    ) {
      if (this.deleteState == 1) {
        this.$toast.fail("您的账号已被限制,请联系客服处理");
        return;
      }
      const isCompleteInfoState = GetLocalStorage("isCompleteInfoState");
      if (isCompleteInfoState == 0 || isCompleteInfoState == "0" || isCompleteInfoState == undefined) {
        this.unregistered()
        return;
      }
      // this.$toast.fail("未开放");
      // return;
      if (memberState == 0) {
        this.$toast.fail("该用户未认证");
        return;
      }
      if (this.memberState == 0) {
        this.$dialog
          .confirm({
            title: "需要认证",
            message: "请先点击“我要认证”再使用本功能",
          })
          .then(() => {
            this.$router.push({ path: "/auth" });
          });
        return;
      }

      if (myopenId == undefined) {
        this.getAuthorizeTips();
        return;
      }
      if (openId == myopenId) {
        this.$toast.fail("不能对自己私聊");
        return;
      }
      SetLocalStorage("customernickname", userName);
      SetLocalStorage("customerheadimgUrl", headimg);
      SetLocalStorage("customerPhone", phone);
      SetLocalStorage("customeropenId", openId);
      SetLocalStorage("sysUserType", sysUserType);
      SetLocalStorage("replyNotChatId", 0);

      this.$router.push({ path: "/chat" });
      return;
    },

    //搜索对象
    async searchobj() {
      this.userlist = [];
      let params = {
        myOpenId: GetToken("myopenId"), // 微信授权用户openId
        maleFemale: this.maleFemale, //女的看男，男的看女
        city: this.city, //城市

        userName: this.userName, //用户名
        maleCount: this.maleValue, //男数量
        femaleCount: this.femaleValue, //女数量
        age: this.age, //年龄
        age1: this.age1, //年龄
        housingType: this.housingType, //是否住房
        position: this.position, //职业
        hobbyType: this.hobbyValue, //爱好分类值
        natureType: this.natureValue, //性格分类值
      };
      let result = await GetUsers(params); //请求接口
      if (result.data != "") {
        result.data.forEach((itme) => {
          this.userlist.push(itme); //加入列表中
        });
      } else {
        this.userlist = [];
      }
      this.positionjob = "";
      this.userName = "";
      this.femaleCount = "";
      this.maleCount = "";
      this.age = "";
      this.age1 = "";
      this.housingType = "";
      this.position = "";
      this.hobbyValue = "";
      this.hobbyType = "";
      this.natureType = "";
      this.natureValue = "";
    },
  },

  // 回到顶部
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },

  // 回到顶部
  unmounted() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.block {
  width: 100%;
  height: 120px;
  color: #000;
  background: #fff;
  margin: 0px 5% 0px 5%;
  border-radius: 5px;
}

>>>.back-btn {
  padding: 0;
  color: #fa74a7;
  top: 70%;
  background-color: rgb(178 178 178 / 0%);
}

.infoa .infoc .van-uploader>>>.van-uploader__preview-image {
  height: 62px;
  width: 62px;
  margin-left: 15px;
  margin-right: 7px;
}

/* .infoa .infoc {
  height: 70px;
  width: 78px;
  margin-bottom: 8px;
  margin-left: 8px;
} */
.infoa .infoc {
  width: 100%;
  float: left;
  margin-left: 2px;
}

>>>.van-uploader__upload {
  display: none;
}

>>>.van-uploader__preview-delete--shadow {
  display: none;
}

>>>.van-uploader__preview-image {
  height: 176px;
  width: 100%;
}

>>>.van-uploader {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

>>>.back-btn {
  font-size: 1.3077rem;
}

>>>.van-uploader__preview {
  width: 100%;
  /* margin-left: 1%; */
}

>>>.van-uploader__wrapper {
  width: 100%;
}

.infoa {
  margin-bottom: 5%;
  padding: 5px;
  /* height: 100%;
  width: 100%;
  display: flex; */
}

/* .infoa .infob {
  width: 78px;
  float: left;
  margin-right: 5px;
} */

.infoa .infoc {
  display: flex;
}


.headimg {
  height: 50px;
  float: left;
  margin-bottom: 6px;
  width: 50px;
  margin-right: 8px;
}

.headimg img,
.headimg {
  height: 55px;
  width: 55px;
}

.van-swipe {
  height: 150px;
  margin-bottom: 0px;
  width: 100%;
}

.personal .consult {
  color: #c91ed2;
  font-weight: bold;
  margin-left: 5%;
  margin-right: 2%;
  font-style: italic;
}

#hobby {
  width: 100%;
  float: left;
  margin-left: 5px;
}

#hobby .hobbyinfo {
  display: flex;
  /* border: 1px solid #4b848e; */
  /* height: 50px; */
  padding: 2px 3px 0px 2px;
  border-radius: 4px;
  /* line-height: 24px; */
  /* margin-right: 10px; */
  overflow: hidden;
  font-weight: bold;
}

.auth {
  margin-left: 0px;
  font-size: 13px;
  padding: 2px;
}

#hobby .youth {
  height: 22px;
  width: 100%;
  display: flex;
  float: left;
}

#hobby .youth p {
  margin-right: 5px;
  margin: 0px;
}

#hobby .youth p span:nth-child(2) {
  margin-right: 5px;
}

#hobby .expect {
  /* height: 65px; */
  width: 100%;
  display: flex;
  float: left;
  text-align: initial;
  margin-bottom: 5px;

  font-size: 14px;
}

#hobby .expect p {
  margin-right: 10px;
  margin: 0px 10px 0px 0px;
}

#hobby .expressionContent {
  margin-bottom: 10px;
  width: 100%;
  display: flex;
  float: left;
  text-align: initial;
  margin-top: 2px;
}

#hobby .expressionContentimg {
  width: 100%;
  /* height: 205px; */
  display: flex;
  float: left;
  margin-bottom: 10px;
}

#hobby .browseinfo {
  height: 31px;
  width: 100%;
  display: flex;
}

#hobby .browse {
  margin-right: 8px;
  width: 70%;
  border: 0px solid;
  color: #000;
}

#hobby .browseinfo .onVideo {
  margin-right: 8px;
  background: #16a8e5;
}

#hobby .browseinfo .addVideo {
  margin-right: 8px;
  background: #23d96e;
}

#hobby .time {
  height: 30px;
  width: 100%;
  display: flex;
}

#hobby .time span {
  margin-right: 10px;
}

#hobby .onVideo {
  margin-right: 8px;
  background: #16a8e5;
}

#hobby .addVideo {
  margin-right: 8px;
  background: #23d96e;
}

.infoa .personalopinion {
  height: 42px;
  width: 100%;
  display: flex;
  /* margin-bottom: 26px; */
  margin-left: 5px;
  text-align: initial !important;
}

.infoa .personalopinion .personalopinioninfo {
  width: 100%;
  height: 45px;
  border: 2px solid #3191b7;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 6px;
  padding: 2px 5px 0px 5px;
}

.infoa .personalopinion .personalopinioninfo i {
  margin-right: 3px;
}

.infoa .personalopinion .personalopinioninfo span:nth-child(2) {
  color: red;
}

.infoa .personalopinion .personalopinioninfo span:nth-child(3) {
  font-size: 14px;
}

>>>.van-nav-bar__left {
  font-size: 25px !important;
}

.personal span:nth-child(2) i {
  color: red;
  margin-right: 2px;
}

.personal span:nth-child(3) {
  color: #c91ed2;
  margin-right: 2px;
}

.van-row .vrow p {
  margin: 0;
  font-size: 14px;
}

.van-row .vrow {
  width: 16.4%;
}

.communication p {
  margin: 0;
  font-size: 14px;
}

.communication {
  /* margin-left: -3px; */
  /* margin-right: 10px; */
  margin: 5px 0px 0px 0px;
}

.infoa .infoc .van-uploader>>>.van-uploader__preview-image {
  height: 62px;
  width: 62px;
  margin-left: 13px;
  margin-right: 7px;
}

>>>.van-notice-bar__wrap,
>>>.van-notice-bar__content {
  position: relative;
  z-index: 1000;
  color: #2c3e50;
}

>>>.van-icon {
  z-index: 1000;
  color: red;
}

/* 
.van-swipe {
 height: 210px; 
}
*/
>>>.meandwomen .van-cell .van-icon {
  color: black !important;
}

>>>.meandwomenzf .van-cell .van-icon {
  color: #fff !important;
}

.expressionContentimg .memberState {
  position: absolute;
  left: 35%;
  color: cornflowerblue;
  padding: 40% 0 0 0px;
  font-size: 15px;
}

.back-top {
  background: 0;
  border-radius: 100%;
  padding: 0.3846rem;
  color: #fff;
  position: fixed;
  top: 77%;
  right: 7%;
  font-size: 2.3077rem;
}

.back-top .back-node {
  font-size: 12px;
  padding: 0;
  margin: -15px;
  color: rgba(178, 178, 178, 0.7);
}

.vannoticebar {
  position: absolute !important;
  width: 78%;
  height: 24px;
  background: 0;
  font-size: 14px;
}

>>>#hobby>div>div.Video>div.communication>div.van-image.van-image--round>img {
  height: 20px;
  widows: 20px;
}



/* 弹窗私聊列表 */

.followinfo {
  width: 100%;
  height: 45px;
  float: left;
  overflow: hidden;
}

.follow {
  width: 85px;
  height: 45px;
  float: right;
}

.follow .followbutton {
  background: #fff;
  border-radius: 25px;
  border: 1px solid;
  color: #07c160;
}

>>>.van-image__img {
  border-radius: 100%;
}

.follow .followedbutton {
  background: #fff;
  border-radius: 25px;
  border: 1px solid gray;
  color: gray;
}

.followinfo p:nth-child(1) {
  font-size: 20px;
}

.followinfo p:nth-child(2),
.followinfo p:nth-child(1) {
  margin: 0 0px 0px 10px;
}

>>>.van-nav-bar__left {
  font-size: 25px !important;
}

.privatereplyNot {
  width: 255px;
  display: inline-block;
}

.privatereplyNot .privateinfo {
  /* height: 30px; */
  height: 18px;
  width: 155px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

>>>.van-popup--center {
  transform: translate3d(-51%, -50%, 0) !important;
}
</style>
