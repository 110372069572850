import request from './request'

export function CarouselOrPageGridOrCommPage(params) { // 轮播栏目-页面宫格-通讯页面
  return request({
    url: '/api/h5/HomeModuls/CarouselOrPageGridOrCommPage',
    method: 'get',
    params
  })
}

//获取其他国家城市
export function GetSysOtherCountrys(params) {
  return request({
    url: "/api/h5/Users/GetSysOtherCountrys",
    method: "get",
    params,
  })
}

export function NewAdd(data) { // 新增基本信息
  return request({
    url: '/api/h5/Users/NewAdd',
    method: 'post',
    data,
    json: 'json'
  })
}

export function UpdateUserInfo(data) { // 更新基本信息
  return request({
    url: '/api/h5/Users/Update',
    method: 'post',
    data,
    json: 'json'
  })
}
export function GetUsers(params) { // 获取首页基本用户信息
  return request({
    url: '/api/h5/Users/GetUsers',
    method: 'get',
    params
  })
}

export function CreateRoomtoken(params) { // 创建roomtoken
  return request({
    url: '/api/h5/Users/CreateRoomtoken',
    method: 'get',
    params
  })
}

export function Regester(data) { //检查是否注册
  return request({
    url: '/api/h5/Users/Regester',
    method: 'post',
    data,
    json: 'json'
  })
}

export function CreateCheckUserAccount(data) { //创建私聊房间号
  return request({
    url: '/api/h5/Users/CreateCheckUserAccount',
    method: 'post',
    data,
    json: 'json'
  })
}
export function CreateCheckVoiceCallsAccount(data) { //创建语音通话房间号
  return request({
    url: '/api/h5/Users/CreateCheckVoiceCallsAccount',
    method: 'post',
    data,
    json: 'json'
  })
}

export function CheckRegester(params) { // 检查是否注册
  return request({
    url: '/api/h5/Users/CheckRegester',
    method: 'get',
    params
  })
}

export function GetVerifyCode(params) { //获取验证码
  return request({
    url: '/api/h5/Users/GetVerifyCode',
    method: 'get',
    params
  })
}
export function VerificationCodeExpired(params) { //验证码是否过期
  return request({
    url: '/api/h5/Users/VerificationCodeExpired',
    method: 'get',
    params
  })
}
export function VerifyPhone(params) { //验证手机码
  return request({
    url: '/api/h5/Users/VerifyPhone',
    method: 'get',
    params
  })
}
export function VerifyUserName(params) { //验证昵称
  return request({
    url: '/api/h5/Users/VerifyUserName',
    method: 'get',
    params
  })
}
export function Myshowroom(params) { //获取我的个人展示
  return request({
    url: '/api/h5/Users/Myshowroom',
    method: 'get',
    params
  })
}

export function Follow(params) { //关注
  return request({
    url: '/api/Follows/Follow',
    method: 'get',
    params
  })
}

export function NewFollw(data) { //新的发布
  return request({
    url: '/api/Follows/NewFollw',
    method: 'post',
    data,
    json: "json"
  })
}
export function NewFollwVideo(data) { //新的发布视频
  return request({
    url: '/api/Follows/NewFollwVideo',
    method: 'post',
    data,
    json: "json"
  })
}

export function UploadImg(data) { //上传图片
  return request({
    url: '/api/Follows/UploadImg',
    method: 'post',
    data,
    json: "json"
  })
}

// export function UploadAudio(data) { //上传音频
//   return request({
//     url: '/api/Follows/UploadAudio',
//     method: 'post',
//     data,
//     json: "json"
//   })
// }

export function GetFileToken(params) { //获取授权token
  return request({
    url: '/api/Follows/GetFileToken',
    method: 'get',
    params
  })
}
// export function GetTypeRecharge(params) { //筛选充值类型
//   return request({
//     url: '/api/h5/Orders/GetTypeRecharge',
//     method: 'get',
//     params
//   })
// }

// export function GetRechargeType(params) { //获取充值类型
//   return request({
//     url: '/api/h5/Orders/GetRechargeType',
//     method: 'get',
//     params
//   })
// }
export function GetUserInfo(params) { //获取个人信息
  return request({
    url: '/api/h5/Users/GetUserInfo',
    method: 'get',
    params
  })
}

export function SearchObj(params) { //搜索对象
  return request({
    url: '/api/h5/Users/SearchObj',
    method: 'get',
    params
  })
}

export function Visit(params) { //累计浏览量
  return request({
    url: '/api/h5/Users/Visit',
    method: 'get',
    params
  })
}

export function ReleaseVisitVisit(params) { //发布累计浏览量
  return request({
    url: '/api/h5/Users/ReleaseVisit',
    method: 'get',
    params
  })
}

export function Details(params) { //发布详细
  return request({
    url: '/api/h5/Users/Details',
    method: 'get',
    params
  })
}
export function GetFollowView(params) { //发布详细
  return request({
    url: '/api/Follows/GetFollowView',
    method: 'get',
    params
  })
}

export function GetContract(params) { //获取合同信息
  return request({
    url: '/api/h5/Contract/GetContract',
    method: 'get',
    params
  })
}

export function GetDeductionContract(params) { //获取扣费合同
  return request({
    url: '/api/h5/Contract/GetDeductionContract',
    method: 'get',
    params
  })
}

export function GetUserContract(params) { //获取合同详情信息
  return request({
    url: '/api/h5/Contract/GetUserContract',
    method: 'get',
    params
  })
}

export function CheckIdentity(params) { //检查身份证
  return request({
    url: '/api/h5/Contract/CheckIdentity',
    method: 'get',
    params
  })
}

export function Auth(data) { //身份认证
  return request({
    url: '/api/h5/Contract/Auth',
    method: 'Post',
    data,
    json: 'json'
  })
}
export function GetAcitvitys(params) { //获取活动
  return request({
    url: '/api/h5/Activity/GetAcitvitys',
    method: 'get',
    params
  })
}

export function GetAmountMoneys(params) { //获取费用说明
  return request({
    url: '/api/h5/Contract/GetAmountMoneys',
    method: 'get',
    params
  })
}

export function GetAmountMoney(params) { //获取费用说明
  return request({
    url: '/api/h5/Contract/GetAmountMoney',
    method: 'get',
    params
  })
}

export function AuthContract(data) { //合同签名
  return request({
    url: '/api/h5/Contract/AuthContract',
    method: 'Post',
    data,
    json: 'json'
  })
}

// export function NewOrder(data) { //新增订单
//   return request({
//     url: '/api/h5/Orders/NewOrder',
//     method: 'Post',
//     data,
//     json: 'json'
//   })
// }

export function GetOrders(params) { //新增订单
  return request({
    url: '/api/h5/Orders/GetOrders',
    method: 'get',
    params,
  })
}

export function Access_token(params) { // 第二步：通过 code 换取网页授权access_token
  return request({
    url: '/api/WeChatWebPageAuth/Access_token',
    method: 'get',
    params
  })
}

export function Refresh_token(params) { //第三步：刷新access_token（如果需要）
  return request({
    url: '/api/WeChatWebPageAuth/Refresh_token',
    method: 'get',
    params
  })
}

export function Userinfo(params) { //第四步：拉取用户信息(需 scope 为 snsapi_userinfo)
  return request({
    url: '/api/WeChatWebPageAuth/Userinfo',
    method: 'get',
    params
  })
}

export function WeChatAuth(params) { //检验授权凭证（access_token）是否有效
  return request({
    url: '/api/WeChatWebPageAuth/WeChatAuth',
    method: 'get',
    params
  })
}

//--------------------------------------------- 是否关注公众号:
export function Token(params) { //
  return request({
    url: '/api/WeChatWebPageAuth/Token',
    method: 'get',
    params
  })
}


export function Info(params) { //
  return request({
    url: '/api/WeChatWebPageAuth/Info',
    method: 'get',
    params
  })
}



//语音通话新增
export function VoiceAdd(data) {
  return request({
    url: '/api/VoiceCalls/VoiceAdd',
    method: 'post',
    data,
    json: 'json'
  })
}
//视频通话新增
export function VideoAdd(data) {
  return request({
    url: '/api/VideoCalls/VideoAdd',
    method: 'post',
    data,
    json: 'json'
  })
}
//视频音频录制
export function Jobs(data) {
  return request({
    url: '/api/VideoCalls/Jobs',
    method: 'post',
    data,
    json: 'json'
  })
}
//更新
export function Update(data) {
  return request({
    url: '/api/VideoCalls/Update',
    method: 'post',
    data,
    json: 'json'
  })
}
//删除任务
export function Stop(data) {
  return request({
    url: '/api/VideoCalls/Stop',
    method: 'post',
    data,
    json: 'json'
  })
}

//获取回放链接
export function Saveas(data) {
  return request({
    url: '/api/VideoCalls/Saveas',
    method: 'post',
    data,
    json: 'json'
  })
}

//聊天记录新增
export function ChatRecordAdd(data) {
  return request({
    url: '/api/h5/ChatRecords/ChatRecordAdd',
    method: 'post',
    data,
    json: 'json'
  })
}

export function GetChatRecords(params) { //获取聊天记录列表
  return request({
    url: '/api/h5/ChatRecords/GetChatRecords',
    method: 'get',
    params,
  })
}
export function IsChatRecords(params) { //获取聊天记录列表
  return request({
    url: '/api/h5/ChatRecords/IsChatRecords',
    method: 'get',
    params,
  })
}
export function GetChatRecordsTaskRun(params) { //获取聊天记录列表
  return request({
    url: '/api/h5/ChatRecords/GetChatRecordsTaskRun',
    method: 'get',
    params,
  })
}

export function GetChatInRecords(params) { //获取聊天中记录
  return request({
    url: '/api/h5/ChatRecords/GetChatInRecords',
    method: 'get',
    params,
  })
}
export function BlockState(params) { //拉黑状态
  return request({
    url: '/api/h5/ChatRecords/BlockState',
    method: 'get',
    params,
  })
}

export function CheckBlockState(params) { //检查拉黑状态
  return request({
    url: '/api/h5/ChatRecords/CheckBlockState',
    method: 'get',
    params,
  })
}

//查看状态更新
export function UpdateReadState(params) {
  return request({
    url: '/api/h5/ChatRecords/UpdateReadState',
    method: 'get',
    params,
  })
}

//查看状态更新
export function UpdateIsClose(params) {
  return request({
    url: '/api/h5/ChatRecords/UpdateIsClose',
    method: 'get',
    params,
    // json: 'json'
  })
}
//是否回复
export function UpdateReplyNot(params) {
  return request({
    url: '/api/h5/ChatRecords/UpdateReplyNot',
    method: 'get',
    params,
  })
}
//检查账号 And 扣费
export function CheckAccount(params) {
  return request({
    url: '/api/VideoCalls/CheckAccount',
    method: 'get',
    params
  })
}

//获取正在视频中状态
export function GetInVideoStates(params) {
  return request({
    url: '/api/VideoCalls/GetInVideoStates',
    method: 'get',
    params
  })
}

//获取拨打状态
export function GetInAudioVideoStates(params) {
  return request({
    url: '/api/VideoCalls/GetInAudioVideoStates',
    method: 'get',
    params
  })
}
//更新视频中状态
export function UpdateVideoState(params) {
  return request({
    url: '/api/VideoCalls/UpdateVideoState',
    method: 'Put',
    params
  })
}
//更新语音中状态
export function UpdateAudioState(params) {
  return request({
    url: '/api/VideoCalls/UpdateAudioState',
    method: 'Put',
    params
  })
}
//语言通话是否接通
export function UpdateVideoIsConnected(params) {
  return request({
    url: '/api/VideoCalls/UpdateVideoIsConnected',
    method: 'Put',
    params
  })
}
//语言通话是否接通
export function UpdateAudioIsConnected(params) {
  return request({
    url: '/api/VideoCalls/UpdateAudioIsConnected',
    method: 'Put',
    params
  })
}
//微信js调后台配置支付
export function Pay(params) {
  return request({
    url: '/api/AliPay/Pay',
    method: 'get',
    params
  })
}

//更新订单状态
export function UpdateOrderState(params) {
  return request({
    url: '/api/AliPay/UpdateOrderState',
    method: 'get',
    params
  })
}

// 是否使用过优惠码
export function IsUsed(params) {
  return request({
    url: '/api/AliPay/IsUsed',
    method: 'get',
    params
  })
}

// 获取优惠码
export function GetMoney(params) {
  return request({
    url: '/api/AliPay/GetMoney',
    method: 'get',
    params
  })
}

//规则信息过滤
export function RuleInfoFilter(params) {
  return request({
    url: '/api/h5/RuleInfo/RuleInfoFilter',
    method: 'get',
    params
  })
}

//获取刷新token
export function AdvertisementToken(params) {
  return request({
    url: '/api/Advertisement/Token',
    method: 'get',
    params
  })
}

//Authorization Code
// export function AdvertisementAuthorize(){
//   return request({
//     url: '/api/Advertisement/Authorize',
//     method: 'get',
//     params
//   })
// }

//上报用户行为数据、上报购买数据(V1.1)
export function Add1v1(params) {
  return request({
    url: "/api/Advertisement/Add1v1",
    method: "get",
    params
  })
}


// 创建用户行为数据源
export function UserActionSetsAdd(params) {
  return request({
    url: "/api/Advertisement/UserActionSetsAdd",
    method: "get",
    params
  })
}
