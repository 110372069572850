export function objpositions() {
  return {
    province_list: {
      100000: "体制外经营",
      110000: '民营私企单位',
      120000: '外企中外合资单位',
      130000: '体制内工作',
      140000: '民办教育培训',
    },
    city_list: {
      // 100000-->>
      100100: '日常街面餐饮业',
      100200: '流动餐饮摊点',
      100300: '商品售卖类经营',
      100400: '生活服务类经营',
      100500: '种植类务农或经营',
      100600: '养殖类务农或经营',
      100700: '网络经营网店配送',
      100800: '医药保健类经营',//end
      100900: '手艺手工业制作',//end

      // 110000-->>
      110100: '私企一般员工',
      110200: '私企办公室行政',
      110300: '私企财务会计',
      110400: '私企后勤服务',
      110500: '私企专业技能师',
      110600: '私企中高层管理',
      110700: '民营医院医疗机构',
      110800: '律所律师法律工作',

      120100: '外企合资企一般员工',
      120200: '外企合资企办公室行政',
      120300: '外企合资企财务后勤',
      120400: '外企合资企专业技能师',
      120500: '外企合资企中高层管理',

      130100: '政府事业编公务员',
      130200: '国企带编职工',
      130300: '国企带编干部',
      130400: '职业军人',
      130500: '国有新闻媒体',
      130600: '体制内编外人员',
      130700: '国有银行金融证券',
      130800: '公办医疗机构',

      130900: '参公企事业单位',
      131000: '公办学前幼儿园',
      131100: '公办中小学',
      131200: '公办大中专院校',

      140100: '广告业',
      140200: '民办大中专院校',
      140300: '民办托管、特殊教育',
      140400: '民办培训机构',
      140500: '其他市场职位',
    },
    county_list: {}
  }
}
