import Cookies from 'js-cookie'

//cookies
export function GetToken(key) {
  return Cookies.get(key)
}

export function SetToken(key, token) {
  return Cookies.set(key, token)
}

export function RemoveToken(key) {
  return Cookies.remove(key)
}

//LocalStorage:
export function SetLocalStorage(name, value) {
  return localStorage.setItem(name, value);
}

export function GetLocalStorage(name) {
  return localStorage.getItem(name);
}

export function RemoveLocalStorage(name) {
  return localStorage.removeItem(name);
}

export function ClearLocalStorage() {
  return localStorage.clear();
}

//Session:
export function SetSessionStorage(name, value) {
  return sessionStorage.setItem(name, value);
}

export function GetSessionStorage(name) {
  return sessionStorage.getItem(name);
}

export function RemoveSessionStorage(name) {
  return sessionStorage.removeItem(name);
}

export function ClearSessionStorage() {
  return sessionStorage.clear();
}
